// @codekit-prepend "../../node_modules/slick-carousel/slick/slick.min.js"

(function($) {
   /* Breakpoints */
  var breakpoints = {large: 1200, medium: 992, small: 768 , xsmall: 767, nav: 1051};



  /* Check window width */
  var resizeTimeout = null;
  var cachedWindowWidth = $(window).width();

  // Check if window width has actually changed
  function checkWindowWidth() {
    var newWindowWidth = $(window).width();

    if (newWindowWidth !== cachedWindowWidth) {
      // If changed call functions on resize
      cachedWindowWidth = newWindowWidth;

      // Add open class to sidebar once filters are loaded
      if (cachedWindowWidth > 991) {
        $('.ast-woocommerce-container .sidebar').addClass('open');
      }

      // Close sidebar on load if screen is 991px or smaller
      if (cachedWindowWidth < 992) {
        $('.ast-woocommerce-container .sidebar').removeClass('open');
      }
    }
  }
  $(window).on('resize', function() {
    if (resizeTimeout !== null) {
      clearTimeout(resizeTimeout);
    }

    resizeTimeout = setTimeout(checkWindowWidth, 50);
  });



  /* Fix ADA errors/issues */
  // function adaFixes() {
  // }
  // adaFixes();



  /* Format phone numbers */
  // Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
  function formatPhoneNumbers() {
    var phoneNumber;
    var phoneNumbers = $('.tel');
    var extension = $('.tel + .ext');

    phoneNumbers.each(function() {
      var $this;

      // Remove '1-' prefix before formatting if it exists
      if ($(this).text().substr(0, 2) === '1-') {
        $this = $(this).text().replace('1-', '');
      } else {
        $this = $(this).text();
      }

      // Set phone number, considering extension if it exists
      if (extension.length) {
        phoneNumber = 'tel:1' + $this.replace(/\D/g, '') + ';ext=' + extension.data('ext');
      } else {
        phoneNumber = 'tel:1' + $this.replace(/\D/g, '');
      }

      $(this).attr('href', phoneNumber);
    });
  }
  formatPhoneNumbers();



  /* Format email addresses */
  // Add email addresses to href if element contains `eml` class
  function formatEmailAddresses() {
    var emailAddress;
    var emailAddresses = $('.eml');

    emailAddresses.each(function() {
      emailAddress = 'mailto:' + $(this).text();
      $(this).attr('href', emailAddress);
    });
  }
  formatEmailAddresses();



  /* Sliders */
  // Hero slider
  function heroSlider() {
    $('.hero-slider.acf-block .slides').each(function() {
      $(this).slick({
        appendArrows: $('.hero-slider.acf-block .slider-controls .arrows'),
        appendDots: $('.hero-slider.acf-block .slider-controls .dots'),
        arrows: false,
        // autoplay: true,
        autoplaySpeed: 5000,
        dots: true,
        // fade: true,
        infinite: true,
        mobileFirst: true,
        nextArrow: '<span class="icon-chevron-right-thin icon" title="Next"></span>',
        prevArrow: '<span class="icon-chevron-left-thin icon" title="Previous"></span>',
        responsive: [
          {
            breakpoint: 1329,
            settings: {
              arrows: true,
            }
          }
        ],
        speed: 400
      });
    });
  }
  if ($('.hero-slider.acf-block').length) {
    heroSlider();
  }

  // Shop filters
  // Allow all filters to be toggled open and closed
  function toggleAllFacetWPFiltersOpenClosed() {
    var facetWPFiltersTitle = $('.widget.filters-title');
    var sidebar = $('.ast-woocommerce-container .shop .sidebar');

    // On click update widget classes
    facetWPFiltersTitle.click(function() {
      if (sidebar.hasClass('open')) {
        sidebar.removeClass('open');
      } else {
        sidebar.addClass('open');
      }
    });
  }
  if ($('.woocommerce .sidebar').length) {
    toggleAllFacetWPFiltersOpenClosed();
  }

  // Allow single widget filters to be toggled open and closed
  function toggleSingleFacetWPFiltersOpenClosed() {
    var facetWPFilterWidgetTitle = $('.widget .filter-title');

    // On click update widget classes
    facetWPFilterWidgetTitle.click(function() {
      if ($(this).parents('.widget').hasClass('hide-options')) {
        $(this).parents('.widget').removeClass('hide-options').addClass('show-options');
      } else if ($(this).parents('.widget').hasClass('show-options')) {
        $(this).parents('.widget').removeClass('show-options').addClass('hide-options');
      }
    });
  }
  if ($('.woocommerce .sidebar').length) {
    toggleSingleFacetWPFiltersOpenClosed();
  }

  // Add open class to sidebar once filters are loaded
  if (cachedWindowWidth > 991) {
    $('.ast-woocommerce-container .sidebar').addClass('open');
  }

  // Close sidebar on load if screen is 991px or smaller
  if (cachedWindowWidth < 992) {
    $('.ast-woocommerce-container .sidebar').removeClass('open');
  }

  // On FacetWP load
  $(document).on('facetwp-loaded', function() {
    // Scroll to top
    if (FWP.loaded) {
      $('html, body').animate({
        scrollTop: $('.shop').offset().top
      }, 100);
    }

    // Only show reset button if facets in use
    var in_use = false;

    $.each(FWP.facets, function(name, val) {
      if (val.length > 0) {
        in_use = true;
      }
    });

    $('.filters-reset').toggle(in_use);

    // Show filters if there are options
    $.each(FWP.settings.num_choices, function(key, val) {
      var $parent = $('.facetwp-facet-' + key).closest('.facetwp-filter');

      (0 !== val) ? $parent.show().removeClass('no-options') : $parent.hide().addClass('no-options');
    });
  });
})(jQuery);
